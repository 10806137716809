<template>
  <base-section id="faq">
    <base-section-heading title="Questions fréquentes">

    </base-section-heading>

    <v-container>
      <v-row align="center">

        <v-expansion-panels inset>
          <v-expansion-panel
            v-for="(item,i) in sections"
            :key="i"
          >
            <v-expansion-panel-header><h3>{{ item.title }}</h3></v-expansion-panel-header>
            <v-expansion-panel-content>
              <p v-for="(descriptionItem, index) in item.description" :key="index">
                {{ descriptionItem }}
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'Faq',

    data: () => ({
      sections: [
        {
          title: 'Quelle est la date limite pour la production de la déclaration de revenus d’un particulier ?',
          description: [
            "Contribuable sans entreprise: Le 30 avril de chaque année (cette date est sujette à changement en raison de la pandémie de COVID-19).",
            "Contribuable travailleur autonome ou ayant un conjoint exploitant une entreprise : Le 15 juin de chaque année (cette date est sujette à changement en raison de la pandémie de COVID-19).",
            "Contribuable décédé durant l’année : six mois après la date du décès ou à la date d’échéance normale de production, selon la plus tardive de ces deux dates.",
          ],
        },
        {
          title: 'Quelle est la date limite d’exigibilité du solde dû ?',
          description: ['Le 30 avril pour tous les particuliers (cette date est sujette à changement en raison de la pandémie de COVID-19).'],
        },
        {
          title: 'Qu’est-ce que la TED et quels sont ses avantages ?',
          description: ['Il s’agit d’un service qui permet de transmettre au gouvernement vos déclarations de revenus de façon électronique.'],
        },
        {
          title: 'Que faire en cas d’oublis ou d’erreurs dans une déclaration de revenus déjà transmise ?',
          description: ['Oublier de demander certains crédits, certaines déductions ou de déclarer certains revenus sont parmi les exemples les plus courants. Il suffit de faire une demande de redressement d’une déclaration de revenus pour modifier votre déclaration.'],
        },
      ]
    }),
  }
</script>

<style scoped>
.aide-memoire-title {
  color: red;
}
</style>